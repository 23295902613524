$fea-green: #69b430;
$fea-green-dark: #458408;
$darkblue: #143b4e;
$white: #fff;
$gray-light: #f4f4f4;
$gray-medium: #e5e5e5;
$gray-dark: #dadada;
$red: #ff4d4d;

$text-color: $darkblue;

// distance from the left and right edge
$page-margin: 7%;
$border-width-normal: 2px;

$font-size-small: 0.7rem;
$font-size-normal: 1rem;
$font-size-medium: 1.5rem;
$font-size-large: 2rem;
$font-size-extra-large: 3rem;

$gap-extra-small: 0.5rem;
$gap-small: 1rem;
$gap-medium: 2rem;
$gap-large: 3rem;
$gap-extra-large: 6rem;

$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-4k: 1921px;

$page-side-gap-sm: 30px;
$page-side-gap-md: 80px;
$page-side-gap-lg: 144px;
