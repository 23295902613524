@import './variables';

.action-button {
  border-radius: 0.5rem !important;

  &--outline {
    background-color: transparent;
    border: 3px solid $fea-green !important;
  }

  &--shadow {
    box-shadow: 2px 4px 10px 0 #545454;
  }
}
