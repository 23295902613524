@import './variables';

.text-gray {
  color: #706f6f;
}

.text-blue {
  color: $darkblue;
}

.text-red {
  color: $red;
}

.bold {
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
  font-weight: bold !important;
}

.font-small {
  font-size: $font-size-small;
}

.font-normal {
  font-size: $font-size-normal;
}

.font-medium {
  font-size: $font-size-medium;
}

.font-large {
  font-size: $font-size-large;
}

.uppercase {
  text-transform: uppercase;
}
