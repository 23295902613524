/* You can add global styles to this file, and also import other style files */
@import 'colors';
@import '~bootstrap/scss/bootstrap';
@import 'buttons';
@import 'fonts';
@import 'carousel';
@import 'flex.scss';
@import 'animations.scss';

@font-face {
  font-family: OpenSans_Regular;
  src: url(../assets/fonts/OpenSans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSans_Bold;
  src: url(../assets/fonts/OpenSans/OpenSans-Bold.ttf);
}

* {
  font-family: OpenSans-Regular, Arial, Helvetica, sans-serif;
}

.grecaptcha-badge {
  visibility: hidden;
}
