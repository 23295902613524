@import './variables';

.flex-container {
  display: flex;
  flex-flow: row wrap;
  margin: #{-$gap-small} #{-$gap-small} 0;

  .col-3,
  .col-2 {
    flex: 100%;
    max-width: 100%;
    padding: $gap-small;
    box-sizing: border-box;
    display: inline-flex;
    flex-flow: row;
  }

  @media (min-width: $bp-md) {
    .col-3 {
      flex: 50%;
      max-width: 50%;
      padding: $gap-small;
    }
  }

  @media (min-width: $bp-lg) {
    .col-2 {
      flex: 50%;
      max-width: 50%;
      padding: $gap-small;
    }
    .col-3 {
      flex: 33.333%;
      max-width: 33.333%;
      padding: $gap-small;
    }
  }

  @media (min-width: $bp-xl) {
    margin: #{-$gap-medium} #{-$gap-medium} 0;
    .col-2,
    .col-3 {
      padding: $gap-medium;
    }
  }
}
