@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal {
  &.show {
    animation: fade-in 0.5s;
  }
}
