@import 'variables';

.sm-carousel {
  .slick-slide {
    overflow: hidden;
  }

  .slick-arrow {
    width: 32px;
    height: 32px;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .prev-arrow {
    background-image: url("../assets/carousel_arrow_left.svg");
  }

  .next-arrow {
    background-image: url("../assets/carousel_arrow_right.svg");
  }
}

.sm-text-carousel-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}

.sm-text-carousel {
  margin-left: $gap-large;
  margin-right: $gap-large;

  &__title {
    font-size: $font-size-medium;
    text-transform: uppercase;
    margin-bottom: $gap-medium;

    @media only screen and (min-width: $bp-sm) {
      font-size: $font-size-large;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    display: none !important;

    li {
      button:before {
        opacity: 1;
        color: $fea-green;
      }

      &.slick-active button:before {
        color: $darkblue;
      }
    }
  }
}

.sm-customers-carousel-wrapper {
  position: relative;
}

.sm-customers-carousel {
  padding-bottom: $gap-large;

  &__slide {
    padding: 10px;
  }

  &__img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    @media only screen and (min-width: $bp-sm) {
      max-height: 90px;
    }
  }

  .slick-arrow {
    display: inline-block;
    position: absolute;
    bottom: 0;
  }

  $customers_carousel_arrows_gap: 26px;

  .prev-arrow {
    right: calc(50% + #{$customers_carousel_arrows_gap/2});
  }

  .next-arrow {
    left: calc(50% + #{$customers_carousel_arrows_gap/2});
  }

}

@media only screen and (min-width: $bp-lg) {
  .sm-text-carousel-wrapper {
    width: 50vw;
  }

  .sm-text-carousel {
    margin-left: 0;
    margin-right: 0;

    .slick-dots {
      display: block;
    }
  }
}

@media only screen and (min-width: $bp-md) {
  .sm-text-carousel {
    .slick-dots {
      display: block !important;
    }
  }
}
